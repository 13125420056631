<template>
  <v-container id="signup" tag="section" width="100%" class="pb-0">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <div style="width: 22em; text-align: center">
          <img
            src="../../assets/logo.png"
            width="105"
            v-if="$vuetify.breakpoint.lgAndDown"
          />
          <img
            src="../../assets/logo.png"
            width="250"
            v-if="!$vuetify.breakpoint.lgAndDown"
          />
          <form
            action
            data-vv-scope="login-form"
            @submit.prevent="validateForm('login-form')"
          >
            <v-row>
              <v-col>
                {{ $t("logear") }}
                <a href="/?#/auth/login">Acceder</a>
              </v-col>
            </v-row>

            <v-row style="width: 100%" class="ma-0">
              <v-col cols="12" md="6" class="py-0">
                <v-card
                  elevation="10"
                  :class="tab == 'artist' ? 'my-2 tab' : 'my-2'"
                  @click="tab = 'artist'"
                >
                  <v-card-title
                    :style="
                      tab == 'artist'
                        ? ' color: var(--v-gris2-base) !important;margin: 0 auto;display: flex;justify-content: center;padding-bottom: 7px;font-size: 20px !important;'
                        : 'margin: 0 auto;display: flex;justify-content: center;padding-bottom: 7px;font-size: 20px !important;'
                    "
                  >
                    <span>{{ $t("artist") }}</span>
                  </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-card
                  elevation="10"
                  :class="tab == 'studio' ? 'my-2 tab' : 'my-2'"
                  @click="tab = 'studio'"
                >
                  <v-card-title
                    :style="
                      tab == 'studio'
                        ? ' color: var(--v-gris2-base) !important;margin: 0 auto;display: flex;justify-content: center;padding-bottom: 7px;font-size: 20px !important;'
                        : 'margin: 0 auto;display: flex;justify-content: center;padding-bottom: 7px;font-size: 20px !important;'
                    "
                  >
                    <span>{{ $tc("studio") }}</span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  hide-details
                  v-model="user.first_name"
                  data-vv-validate-on="blur"
                  v-validate="'required|min:2'"
                  type="text"
                  data-vv-name="fist_name"
                  color="primary"
                  :label="tab == 'artist' ? $t('name') : $t('studios.name')"
                  outlined
                  :error-messages="errors.collect('login-form.fist_name')"
                  class="color v-text-field rounded-lg"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                /> </v-col
            ></v-row>

            <v-row style="margin-top: -15px">
              <v-col>
                <v-text-field
                  hide-details
                  v-model="user.phone"
                  data-vv-validate-on="blur"
                  v-validate="'required|min:2|max:9'"
                  type="number"
                  v-on:keypress="isNumber($event)"
                  data-vv-name="phone"
                  color="primary"
                  :label="$t('phone')"
                  outlined
                  :error-messages="errors.collect('login-form.phone')"
                  v-mask="'#########'"
                  class="color v-text-field rounded-lg"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                /> </v-col
            ></v-row>

            <v-row style="margin-top: -15px">
              <v-col>
                <v-text-field
                  hide-details
                  v-model="user.email"
                  data-vv-validate-on="blur"
                  v-validate="'required|email'"
                  type="email"
                  data-vv-name="email"
                  color="primary"
                  :label="$t('email')"
                  outlined
                  :error-messages="errors.collect('login-form.email')"
                  class="color v-text-field rounded-lg"
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                /> </v-col
            ></v-row>

            <v-row style="margin-top: -15px">
              <v-col>
                <v-text-field
                  hide-details
                  v-model="user.password"
                  data-vv-validate-on="blur"
                  v-validate="'required|min:2'"
                  :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="show = !show"
                  :type="show ? 'text' : 'password'"
                  data-vv-name="password"
                  color="primary"
                  :label="$t('password')"
                  :error-messages="errors.collect('login-form.password')"
                  outlined
                  class="color v-text-field rounded-lg"
                /> </v-col
            ></v-row>

            <v-row style="margin-top: -15px" v-if="tab !== 'studio'">
              <v-col>
                <v-select
                  hide-details
                  v-model="user.type"
                  multiple
                  :items="items"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  data-vv-name="type"
                  color="primary"
                  :label="$t('tattooers.artist_type')"
                  :error-messages="errors.collect('login-form.type')"
                  outlined
                  item-value="id"
                  :item-text="(x) => $tc(x.name)"
                  class="color v-text-field rounded-lg"
                /> </v-col
            ></v-row>
            <v-row style="margin-top: -15px">
              <v-col cols="12">
                <v-checkbox
                  v-model="selected"
                  v-validate="'required|is:true'"
                  true-value="true"
                  data-vv-name="share"
                  hide-details
                  false-value="false"
                  :error-messages="errors.collect('login-form.share')"
                  on-icon="mdi-check-circle"
                  off-icon="mdi-circle-outline"
                >
                  <template v-slot:label>
                    <div style="margin-top: 6px">
                      {{ $t("check_condicions") }}
                      <a
                        @click.stop
                        target="blank"
                        href="https://www.quickink.es/#/terms-conditions"
                      >
                        {{ $t("check_condicions_links") }}
                      </a>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>

            <base-material-alert
              style="
                margin-top: 10px;
                width: 140%;
                margin-left: -20%;
                padding-left: 10%;
                padding-right: 10%;
                border-radius: 10px;
              "
              v-if="$store.state.auth.errorLogin"
              color="login_error"
              dark
              >{{ $t("repited-email") }}
              <a
                href="/?#/auth/password_forgot"
                style="color: white; font-size: 14px"
              >
                {{ $t("forgot_password") }}
              </a>
            </base-material-alert>
            <v-row
              ><v-col class="pt-0">
                <v-btn
                  block
                  large
                  color="primary"
                  type="submit"
                  elevation="0"
                  style="font-size: 18px; text-transform: none"
                  >{{ $t("lets_go") }}</v-btn
                >
              </v-col></v-row
            >
            <!--
              <v-row align="center" style="color: var(--v-primary-base)">
              <v-divider
                style="
                  background-color: var(--v-primary-base);
                  margin-right: 10px;
                "
              ></v-divider>
              {{ $t("or_login_with") }}
              <v-divider
                style="
                  background-color: var(--v-primary-base);
                  margin-left: 10px;
                "
              ></v-divider>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  @click="gmailSingIn"
                  rounded
                  block
                  large
                  style="
                    color: var(--v-primary-base);
                    background-color: transparent;
                    border: 2px solid var(--v-primary-base);
                    font-size: 18px;
                    text-transform: none;
                  "
                >
                  <v-icon style="margin-right: 20px">$google</v-icon>Google
                </v-btn>
              </v-col>
            </v-row>-->
          </form>
        </div>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import MaterialAlert from "@/components/base/MaterialAlert";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "PagesSingIn",

  $_veeValidate: {
    validator: "new",
  },

  components: {
    MaterialAlert,
    PagesBtn: () => import("./components/Btn"),
  },

  directives: {
    mask,
  },
  data: () => ({
    user: {
      email: null,
      first_name: null,
      phone: null,
      password: null,
      token: null,
      timetable: [[], [], [], [], [], [], []],
      role: null,
      type: null,
    },
    show: false,
    items: [],
    tab: "artist",
  }),

  mounted() {
    //console.log("entramos al signup");
    this.fetchTypes();
    this.$store.commit("auth/RESET_INVALID_CREDENTIALS", null, {
      root: true,
    });
  },

  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    ...mapActions("tattooers", ["getTypeArtist"]),
    fetchTypes() {
      this.getTypeArtist().then((tattooers) => {
        ////console.log(tattooers);
        this.items = tattooers.data;
      });
    },
    ...mapActions("auth", ["authenticate", "authenticateOauth", "addUser"]),

    async validateForm(scope) {
      const result = await this.$validator.validateAll(scope);
      if (result) {
        this.user.role = this.tab == "artist" ? 2 : 3;

        //console.log(this.user);
        this.$store.commit("auth/RESET_INVALID_CREDENTIALS");

        await this.addUser(this.user);
        this.postSignUp();
      }
    },
    async gmailSingIn() {
      this.$store.commit("auth/RESET_INVALID_CREDENTIALS");

      let GoogleUser = await this.$gAuth.signIn();

      await this.$store.dispatch("auth/authenticateOauth", {
        type: "google",
        token_id: GoogleUser.getId(),
      });
      this.postLogin();
    },

    async postSignUp() {
      //console.log("postLogin");
      if (!this.$store.state.auth.errorLogin) {
        //console.log("NO ERROR");

        if (this.$route.params.inscription) {
          //this.$router.back();
        } else {
          if (this.tab === "artist") this.$router.push({ name: "Dashboard" });
          else
            await this.$router.push({
              name: "FirstSteps",
              params: {
                type: this.tab == "studio" ? "alerts" : "profile",
                calendar: true,
              },
            });
        }
      } else {
        //console.log("ERROR");
        this.$store.commit(
          "app/SET_PROCESSING",
          {
            show: false,
            message: null,
          },
          {
            root: true,
          }
        );
      }
    },
  },
};
</script>
<style lang="sass">
#signup
  margin-top: 50px
  label
    margin-top: -10px
  #selector

    .color .v-label
      color: var(--v-primary-base) !important

  .color .v-label
    color: var(--v-primary-base) !important
  input
    padding-top: 0
    padding-bottom: 0
    font-size: 16px !important
    margin-top: 0px
    height: 20px !important
  .tab
    background-color: var(--v-primary-base) !important
  fieldset
    height: 100%
  .v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot
    min-height: 40px
  .v-text-field--full-width .v-input__prepend-outer, .v-text-field--full-width .v-input__prepend-inner, .v-text-field--full-width .v-input__append-inner, .v-text-field--full-width .v-input__append-outer, .v-text-field--enclosed .v-input__prepend-outer, .v-text-field--enclosed .v-input__prepend-inner, .v-text-field--enclosed .v-input__append-inner, .v-text-field--enclosed .v-input__append-outer
    margin-top: 6px
  .v-select__selections
    padding: 0

  .v-text-field
    border-radius: 15px
    display: flex
    justify-content: center
    align-items: center

@media (max-width: 400px)

#signup
  .v-input--selection-controls__ripple
    margin-top: -4px !important
    margin-left: 8px !important
  .v-input--selection-controls__input .v-icon
    margin-top: -22px !important
  .v-input__slot
    padding-bottom: 10px !important
  .v-text-field input
    width: 200px
    height: 15px
  .v-btn:not(.v-btn--round).v-size--large
    height: 40px
  .v-text-field
    border-radius: 15px
    display: flex
    justify-content: center
    align-items: center
  fieldset
    widht: 100% !important
    height: 100%
</style>
